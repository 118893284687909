import {
  WidgetType,
  RequestJSONSchema,
  SimpleResponse,
  UiOptions,
  UiEnumSpec,
} from './types';

export function parseJsonSchema(jsonSchema: string): RequestJSONSchema | null {
  try {
    const metadata = JSON.parse(jsonSchema) as RequestJSONSchema;
    return metadata;
  } catch (_) {
    return null;
  }
}

export function parseSimpleData(data: string): SimpleResponse | null {
  try {
    const initialData = JSON.parse(data) as SimpleResponse;
    return initialData;
  } catch (_) {
    return null;
  }
}

export function inputTypeFromSchema(field: RequestJSONSchema): WidgetType {
  if (field['ui:options']?.widget) {
    return field['ui:options']?.widget;
  } else if (field['ui:widget']) {
    return field['ui:widget'];
  } else if (field.enum) {
    return WidgetType.SELECT;
  } else {
    switch (field.type) {
      case 'integer':
      case 'number': {
        return WidgetType.NUMERIC;
      }
      case 'boolean': {
        return WidgetType.CHECKBOX;
      }
      case 'array': {
        return WidgetType.ARRAY;
      }
      // @ts-ignore
      case 'data-url': {
        return WidgetType.FILE;
      }
      case 'string': {
        if (!field.format) {
          return WidgetType.TEXT;
        } else {
          switch (field.format) {
            case 'data-url': {
              return WidgetType.FILE;
            }
            case 'date': {
              return WidgetType.DATE;
            }
            case 'date-time': {
              return WidgetType.DATE_TIME;
            }
            case 'password': {
              return WidgetType.PASSWORD;
            }
            default: {
              return WidgetType.TEXT;
            }
          }
        }
      }
      default: {
        return WidgetType.TEXT;
      }
    }
  }
}

export function titleFromSchema(schema: RequestJSONSchema): string {
  if (schema['ui:title']) {
    return schema['ui:title'];
  } else if (schema['ui:options']?.title) {
    return schema['ui:options'].title;
  } else if (schema.title) {
    return schema.title;
  }
  return '';
}

export function descriptionFromSchema(schema: RequestJSONSchema): string {
  if (schema['ui:description']) {
    return schema['ui:description'];
  } else if (schema['ui:options']?.description) {
    return schema['ui:options'].description;
  } else if (schema.description) {
    return schema.description;
  }
  return '';
}

export function widthFromSchema(schema: RequestJSONSchema): number {
  if (schema['ui:width']) {
    return schema['ui:width'];
  } else if (schema['ui:options']?.width) {
    return schema['ui:options'].width;
  }
  return NaN;
}

export function formattingFromSchema(schema: RequestJSONSchema): string {
  if (schema['ui:formatting']) {
    return schema['ui:formatting'];
  } else if (schema['ui:options']?.formatting) {
    return schema['ui:options'].formatting;
  }
  return '';
}

export function enumFromSchema(schema: RequestJSONSchema): UiEnumSpec[] {
  if (schema['ui:options']?.enum) {
    return schema['ui:options'].enum;
  } else if (schema['ui:enum']) {
    return schema['ui:enum'];
  } else if (schema.enum) {
    return schema.enum.map((e) => ({
      id: e as string,
      name: e as string,
      disabled: false,
      description: '',
    }));
  }
  return [];
}

export function uiOptionsFromSchema(schema: RequestJSONSchema): UiOptions {
  const options: UiOptions = {};

  // widget type
  options.widget = inputTypeFromSchema(schema);
  options.title = titleFromSchema(schema);
  options.description = descriptionFromSchema(schema);
  options.width = widthFromSchema(schema);
  options.formatting = formattingFromSchema(schema);

  return options;
}

export function formatDisplayText(
  schema: RequestJSONSchema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | null
): string | null {
  const formatting = formattingFromSchema(schema);
  if (value && formatting) {
    // TODO: for now, just support numbers
    if (schema.type === 'integer' || schema.type === 'number') {
      // this regex matches a basic numeric formatting string per the specification for printf
      // %<width><type>.<precision>
      // Ex: %e.3
      // would be exponent notation with 3 digits of precision
      const match = new RegExp(/^%(\d*)([efd])(\.(\d+))?/);
      const result = match.exec(formatting);
      const numVal = Number.parseFloat(value);
      let type = 'd',
        precision: number | undefined;
      if (result) {
        // width = Number.parseInt(result[1]);
        type = result[2];
        precision = Number.parseInt(result[4]);
        switch (type) {
          default:
          case 'd': {
            return Math.ceil(numVal).toString();
          }
          case 'f': {
            return numVal.toPrecision(precision);
          }
          case 'e': {
            return numVal.toExponential(precision);
          }
        }
      } else {
        console.error(
          `Found invalid formatting string in schema: ${formatting}`
        );
      }
    }
  } else if (value && schema.type === 'array') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const array = value.split(',').map((v) => v.trim());
    const formattedArray = `[${array
      .filter((v) => v.toString() !== '')
      .map((v) => v.toString())}]`;
    return formattedArray;
  }
  return value;
}
